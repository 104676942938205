@tailwind base;
@tailwind components;
@tailwind utilities;

.logo {
    height: 50px;
    display: inline;
    margin-right:5px;
  }

  .items-center {
    align-items: center;
    text-align: center;
    justify-content: center;
}

.bg-yellow-500{
    background-color: #bf2ca4;
}

.bg-green-500 {
    background-color: #33135E
}